<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_registration')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('course_registration')"
                    :isFilter="false"
                />
            </template>
            <div v-if="systemStatus == 'success'">
                <b-row>
                    <b-col cols="12" md="4" lg="4" xl="3">
                        <h6 class="mb-2">
                            {{
                                student.student_number
                                    ? student.student_number
                                    : "-"
                            }}
                            - {{ student.name ? student.name : "" }}
                            {{ student.surname ? student.surname : "" }}
                        </h6>
                        <div class="border rounded p-3 mb-1 mb-md-5">
                            <div class="mb-1">
                                {{ student.program_name }}
                            </div>
                            <div>
                                {{ student.class_name }}
                                {{
                                    student.number_of_semesters
                                        ? " - " +
                                          $t("semester_x", {
                                              semester:
                                                  student.number_of_semesters,
                                          })
                                        : ""
                                }}
                            </div>
                            <div>
                                {{ student.success_status_name || "-" }}
                            </div>
                        </div>
                        <div class="border rounded p-3 mb-1 mb-md-5">
                            <b-form-group :label="$t('gno')" class="mb-0">
                                <div class="label-as-input">
                                    {{ student.cgpa ? student.cgpa : "-" }}
                                </div>
                            </b-form-group>
                        </div>
                        <div class="border rounded p-3 text-center mb-2">
                            <b-row>
                                <b-col cols="4" md="12">
                                    <b-form-group
                                        :label="
                                            $t('course_selection_credit_limit')
                                        "
                                        class="mb-2"
                                    >
                                        <div class="label-as-input">
                                            {{
                                                student.credit_limit
                                                    ? student.credit_limit
                                                    : 0
                                            }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4" md="6">
                                    <b-form-group
                                        :label="$t('credits_attempted')"
                                        class="mb-2"
                                    >
                                        <div class="label-as-input">
                                            {{
                                                student.sum_of_credits
                                                    ? student.sum_of_credits
                                                    : 0
                                            }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4" md="6">
                                    <b-form-group
                                        :label="$t('remaining_credit')"
                                        class="mb-2"
                                    >
                                        <div class="label-as-input">
                                            {{
                                                student.remaining_credits
                                                    ? student.remaining_credits
                                                    : 0
                                            }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4" md="6">
                                    <b-form-group
                                        :label="$t('number_of_courses_taken')"
                                        class="mb-2"
                                    >
                                        <div class="label-as-input">
                                            {{
                                                student.count_of_courses
                                                    ? student.count_of_courses
                                                    : 0
                                            }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col cols="12" md="8" lg="8" xl="9">
                        <h6 class="mb-2">
                            {{ student.academic_year }} -
                            {{ student.semester_name }}
                        </h6>
                        <div class="border rounded-sm p-4">
                            <div class="tabs-no-tabcontent position-relative">
                                <TabButton
                                    v-model="selectedTab"
                                    :values="tabs"
                                />
                                <div class="tabs-bottom-line"></div>
                            </div>
                            <div class="pt-5 pb-0">
                                <div v-if="selectedTab == 'general'">
                                    <div class="mb-3">
                                        <h6 class="mb-3">
                                            {{ $t("information") }}
                                        </h6>
                                        <div class="border rounded-sm p-4">
                                            <information></information>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <h6
                                            class="mb-0 mr-3 d-inline-block mb-3"
                                        >
                                            {{ $t("your_chosen_courses") }}
                                        </h6>
                                        <div>
                                            <b-button
                                                :variant="
                                                    showType == 'list'
                                                        ? 'primary'
                                                        : 'outline-primary'
                                                "
                                                class="mr-1 d-inline-block"
                                                @click="showType = 'list'"
                                            >
                                                <i
                                                    class="ri-file-list-2-line mr-1"
                                                ></i>
                                                {{ this.toUpperCase("list") }}
                                            </b-button>
                                            <b-button
                                                :variant="
                                                    showType == 'calendar'
                                                        ? 'primary'
                                                        : 'outline-primary'
                                                "
                                                class="d-inline-block"
                                                @click="showType = 'calendar'"
                                            >
                                                <i
                                                    class="ri-calendar-todo-fill mr-1"
                                                ></i>
                                                {{
                                                    this.toUpperCase(
                                                        "course_schedule"
                                                    )
                                                }}
                                            </b-button>
                                        </div>
                                    </div>
                                    <div v-if="showType == 'list'">
                                        <b-table
                                            :empty-filtered-text="
                                                $t('no_result')
                                            "
                                            :empty-text="$t('no_result')"
                                            bordered
                                            striped
                                            responsive
                                            :items="takenCourses"
                                            :fields="takenCourseFields"
                                            class="mb-0 table-dropdown no-scrollbar border rounded"
                                        >
                                            <template
                                                #cell(dropdownTable)="data"
                                            >
                                                <b-dropdown
                                                    variant="link btn-sm"
                                                    boundary="window"
                                                    ref="dropdown"
                                                    no-caret
                                                >
                                                    <template #button-content>
                                                        <i
                                                            class="ri-more-fill"
                                                        ></i>
                                                    </template>
                                                    <div
                                                        id="dropdownList"
                                                        v-if="
                                                            !data.item.is_locked
                                                        "
                                                    >
                                                        <b-dropdown-item
                                                            v-if="
                                                                !data.item
                                                                    .is_locked &&
                                                                data.item
                                                                    .course_id &&
                                                                data.item
                                                                    .allow_section_change ==
                                                                    true &&
                                                                data.item
                                                                    .section_number
                                                            "
                                                            @click="
                                                                getSectionList(
                                                                    data.item
                                                                        .course_id,
                                                                    data.item
                                                                        .section_id,
                                                                    1
                                                                )
                                                            "
                                                        >
                                                            <span>
                                                                <i
                                                                    class="ri-pencil-fill align-middle top-minus-1 mr-3 text-muted"
                                                                ></i>
                                                                {{
                                                                    $t(
                                                                        "theoretical"
                                                                    )
                                                                }}
                                                                -
                                                                {{
                                                                    $t(
                                                                        "change_section"
                                                                    )
                                                                }}
                                                            </span>
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            v-if="
                                                                data.item
                                                                    .course_id &&
                                                                data.item
                                                                    .allow_section_change ==
                                                                    true &&
                                                                data.item
                                                                    .lab_section_number
                                                            "
                                                            @click="
                                                                getSectionList(
                                                                    data.item
                                                                        .course_id,
                                                                    data.item
                                                                        .section_id,
                                                                    2
                                                                )
                                                            "
                                                        >
                                                            <span>
                                                                <i
                                                                    class="ri-pencil-fill align-middle top-minus-1 mr-3 text-muted"
                                                                ></i>
                                                                {{ $t("lab") }}
                                                                -
                                                                {{
                                                                    $t(
                                                                        "change_section"
                                                                    )
                                                                }}
                                                            </span>
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            @click="
                                                                deleteCourse(
                                                                    data.item
                                                                        .student_slot_id,
                                                                    data.item
                                                                        .course_id
                                                                )
                                                            "
                                                        >
                                                            <span>
                                                                <i
                                                                    class="ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted"
                                                                ></i>
                                                                {{
                                                                    $t("delete")
                                                                }}
                                                            </span>
                                                        </b-dropdown-item>
                                                    </div>
                                                </b-dropdown>
                                            </template>
                                            <template #cell(slot_type)="data">
                                                {{ data.item.slot_type_text }}
                                            </template>
                                            <template #cell(credit)="data">
                                                {{
                                                    data.item.credit
                                                        ? parseInt(
                                                              data.item.credit
                                                          )
                                                        : "-"
                                                }}
                                            </template>
                                            <template #cell(course_name)="data">
                                                {{ data.item.course_name }}
                                            </template>
                                        </b-table>
                                    </div>
                                    <div v-if="showType == 'calendar'">
                                        <time-table
                                            :takenSectionIds="takenSectionIds"
                                        ></time-table>
                                    </div>
                                </div>
                                <template v-for="slotData in slots">
                                    <div :key="slotData.slot_type"
                                        v-if="selectedTab == slotData.slot_type">
                                        <slot-page
                                            :slotData="slotData"
                                            :studentProgramId="studentProgramId"
                                            @addedACourse="addedACourse()"
                                        />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <CommonModal
                    ref="changeSectionModal"
                    :onHideOnlyX="true"
                    size="lg"
                >
                    <template v-slot:CommonModalTitle>
                        {{ $t("change") }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <div v-if="changeSectionList">
                            <div class="d-flex">
                                <div class="flex-grow-1 mr-2">
                                    <b-form-select
                                        v-model="changeSectionSelect"
                                    >
                                        <option
                                            v-for="list in changeSectionList"
                                            :value="list.id"
                                            :key="list.id"
                                        >
                                            {{ list.section }}
                                            {{
                                                list.instructor
                                                    ? " - " +
                                                      list.instructor.name +
                                                      " " +
                                                      list.instructor.surname
                                                    : ""
                                            }}
                                            {{
                                                "- " +
                                                $t("remaining_quota") +
                                                ": " +
                                                list.remaining_quota
                                            }}
                                            {{
                                                (list.course_schedules || [])
                                                    .length
                                                    ? "- " +
                                                      $t("date") +
                                                      ": " +
                                                      getDayText(
                                                          list
                                                              .course_schedules[0]
                                                              .day
                                                      ) +
                                                      "- " +
                                                      $t("start_time") +
                                                      ":" +
                                                      getObjectValue(
                                                          list
                                                              .course_schedules[0],
                                                          "start_time"
                                                      )
                                                    : ""
                                            }}
                                        </option>
                                    </b-form-select>
                                </div>
                                <div>
                                    <b-button
                                        variant="primary"
                                        @click="
                                            changeSection(
                                                changeSectionCourseId,
                                                changeSectionSelect,
                                                changeSectionType
                                            )
                                        "
                                    >
                                        {{ $t("save") }}
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            {{ $t("no_result") }}
                        </div>
                    </template>
                </CommonModal>
            </div>
            <div v-else-if="systemStatus == 'error'">
                <warning-page
                    :title="$t('information')"
                    :message="$t('api.' + systemStatusMessage)"
                />
            </div>
        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import TabButton from "@/components/elements/TabButton.vue";
import CommonModal from "@/components/elements/CommonModal";

// Page
import Information from "./Information";
import SlotPage from "./SlotPage";
import TimeTable from "./TimeTable";
import WarningPage from "@/components/page/WarningPage";

// Services
import CourseRegistration from "@/services/CourseRegistration";
import qs from "qs";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        TabButton,
        Information,
        SlotPage,
        TimeTable,
        CommonModal,
        WarningPage,
    },
    metaInfo() {
        return {
            title: this.$t("course_registration"),
        };
    },
    data() {
        return {
            // Student
            studentProgramId: null,
            student: [],

            // Tabs
            tabs: [],
            selectedTab: null,

            // Slots
            slots: [],

            // System
            systemStatus: null,
            systemStatusMessage: null,

            // Show Type
            showType: "list",

            // Taken
            takenCourses: [],
            takenSectionIds: [],
            takenCourseFields: [
                {
                    key: "dropdownTable",
                    label: " ",
                    class: "p-0 text-center w-40 align-middle",
                },
                {
                    key: "course_code",
                    label: this.$t("course_code"),
                    sortable: true,
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    key: "course_name",
                    label: this.$t("course"),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                },
                {
                    key: "section_number",
                    label: this.$t("section").toUpperCase(),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                },
                {
                    key: "lab_section_number",
                    label: this.$t("lab_section").toUpperCase(),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                },
                {
                    key: "slot_type",
                    label: this.$t("slot_type"),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                },
                {
                    key: "slot_semester",
                    label: this.$t("semester"),
                    sortable: true,
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    key: "credit",
                    label: this.$t("credit"),
                    sortable: true,
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    key: ("repeated_course.course_name"),
                    label: this.$t("last_course"),
                    sortable: true,
                },
                {
                    key: "repeated_course.letter_grade",
                    label: this.$t("letter_grade"),
                    sortable: true,
                },
            ],
            creditLimit: 0,
            creditTotal: 0,

            changeSectionCourseId: null,
            changeSectionSelect: null,
            changeSectionType: null,
            changeSectionList: [],
        };
    },
    mounted() {
        this.loading = true;

        this.studentProgramId = this.$route.params.id;

        if (this.studentProgramId !== undefined) {
            this.getSlots().then(() => {
                this.loading = false;
            });
        } else {
            this.getFromUser().then(() => {
                this.getSlots().then(() => {
                    this.loading = false;
                });
            });
        }
    },
    methods: {
        async getFromUser() {
            let user = await this.$store.getters["auth/getUser"];
            if (
                user &&
                user.active_student_program &&
                user.active_student_program.id
            ) {
                this.studentProgramId = user.student.id;
            } else {
                this.$router.push("/404");
            }
        },
        async getSlots() {
            this.systemStatus = null;
            await CourseRegistration.getSlots(this.studentProgramId)
                .then((response) => {
                    this.systemStatus = "success";

                    this.student = response.data.data.student;
                    this.slots = response.data.data.slots;

                    this.takenCourses = [];
                    this.takenCourses = response.data.data.taken_courses;
                    this.takenSectionIds = response.data.data.taken_section_id;
                })
                .then(() => {
                    this.tabs = [];
                    this.tabs.push({
                        key: "general",
                        text: this.$t("information"),
                        icon: "ri-file-list-line",
                    });
                    if (this.slots.length > 0) {
                        this.slots.forEach((item) => {
                            this.tabs.push({
                                key: item.slot_type,
                                text: item.slot_type_name,
                                icon: "ri-article-line",
                            });
                        });
                    }

                    if (!this.selectedTab) {
                        this.selectedTab = "general";
                    }
                })
                .catch((e) => {
                    this.systemStatus = "error";
                    this.systemStatusMessage = e.data.message;
                });
        },

        // Added
        addedACourse(studentProgramId, slotId, courseId) {
            this.showType = "list";
            this.getSlots();
            this.selectedTab = 0;
            this.showType = "calendar";
        },

        // Section Change
        getSectionList(courseId, selected, type) {
            this.changeSectionCourseId = courseId;
            this.changeSectionSelect = selected;
            this.changeSectionType = type;
            const config = {
                params: {
                    type: type,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };
            CourseRegistration.getChangeSection(
                this.studentProgramId,
                courseId,
                config
            )
                .then((response) => {
                    this.changeSectionList = response.data.data;
                })
                .then((response) => {
                    this.$refs.changeSectionModal.$refs.commonModal.show();
                });
        },
        changeSection(courseId, sectionId, type) {
            CourseRegistration.putChangeSection(
                this.studentProgramId,
                courseId,
                sectionId,
                type
            )
                .then((response) => {
                    this.$toast.success(this.$t("successfully_changed"));
                    this.$refs.changeSectionModal.$refs.commonModal.hide();
                    this.getSlots();
                })
                .catch((e) => {
                    this.showErrors(e);
                });
        },

        // Delete
        deleteCourse(slotId, courseId) {
            this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_delete"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        CourseRegistration.del(
                            this.studentProgramId,
                            slotId,
                            courseId
                        )
                            .then((response) => {
                                this.$toast.success(
                                    this.$t("successfully_deleted")
                                );
                                this.getSlots();
                            })
                            .catch((error) => {
                                this.$toast.error(
                                    this.$t("api." + error.data.message)
                                );
                            });
                    }
                });
        },
    },
};
</script>
