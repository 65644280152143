<template>
    <div v-if="slotData && slotData.slot_type">
        <template v-for="(slotList,slotType) in slotData.slots">
        <div 
        :key="slotType"
             v-if="slotData.slots">
            <div class="mb-2">
                <h6 class="mb-0" v-if="slotType=='grade_up'">{{ $t('grade_up_lessons') }}</h6>
                <h6 class="mb-0" v-if="slotType=='repeat'">{{ $t('lessons_you_have_taken') }}</h6>
                <h6 class="mb-0" v-if="slotType=='not_taken'">{{ $t('lessons_we_have_not_taken_before') }}</h6>
            </div>
            <b-table :empty-filtered-text="$t('no_result')"
                     :empty-text="$t('no_result')"
                     bordere
                     striped
                     responsive
                     :items="slotList"
                     :fields="getFields(slotData.slot_type, slotType)"
                     show-empty
                     class="mb-3 table-dropdown no-scrollbar border rounded"
            >
                <template #cell(add)="data">
                    <template v-if="data.item.course_id">
                        <div class="w-40 h-40 cursor-pointer d-flex justify-content-center align-items-center"
                             @click="data.toggleDetails">
                            <i :class="data.detailsShowing ? 'ri-arrow-up-s-line font-size-20':'ri-arrow-down-s-line font-size-20'"></i>
                        </div>
                    </template>
                    <template v-else-if="data.item.pool_id">
                        <b-button variant="primary" class="btn-icon"
                                  @click="getElectiveCourses(data.item.id, data.item.pool.name)">
                            <i class="ri-add-line"></i>
                        </b-button>
                    </template>
                </template>
                <template #cell(course_code)="data">
					<span v-if="data.item.course_id">
						{{ data.item.course && data.item.course.code ? data.item.course.code : '-' }}
					</span>
                    <span v-else>
						-
					</span>
                </template>
                <template #cell(course_name)="data">
					<span v-if="data.item.course_id">
						{{ data.item.course && data.item.course.name || '-' }}
					</span>
                </template>
                <template #cell(pool_name)="data">
					<span v-if="data.item.pool_id">
						{{ data.item.pool && data.item.pool.name || '-' }}
					</span>
                </template>
                <template #cell(credit)="data">
					<span v-if="data.item.course_id">
						{{ data.item.course && data.item.course.credit ? parseInt(data.item.course.credit) : '-' }}
					</span>
                    <span v-else>
						{{ data.item.credit ? parseInt(data.item.credit) : '-' }}
					</span>
                </template>
                <template #cell(last_course)="data" v-if="slotType!=4">
					<span v-if="data.item.last_course">
						{{ data.item.last_course.course_code ? data.item.last_course.course_code : '' }}
						-
						{{
                            data.item.last_course.course_name || '-'
                        }}
					</span>
                </template>
                <template #cell(last_course_letter_grade)="data" v-if="slotType!=4">
					<span v-if="data.item.last_course">
						{{ data.item.last_course.letter_grade ? data.item.last_course.letter_grade : '-' }}
					</span>
                </template>
                <template #row-details="data">
                    <div class="row" v-if="data.item.course_id">
                        <div class="col-12">
                            <b-row class="mb-2">
                                <!-- Teorik -->
                                <b-col cols="3" sm="3" lg="auto" class="mb-2">
                                    <div class="text-muted mb-0">{{ $t('theoric') }}</div>
                                    <div class="label-as-input text-center">
                                        {{
                                            data.item.course.theoretical_credit ? parseInt(data.item.course.theoretical_credit) : '-'
                                        }}
                                    </div>
                                </b-col>

                                <!-- Pratik -->
                                <b-col cols="3" sm="3" lg="auto" class="mb-2">
                                    <div class="text-muted mb-0">{{ $t('practical') }}</div>
                                    <div class="label-as-input text-center">
                                        {{
                                            data.item.course.practical_credit ? parseInt(data.item.course.practical_credit) : '-'
                                        }}
                                    </div>
                                </b-col>

                                <!-- Credit -->
                                <b-col cols="3" sm="3" lg="auto" class="mb-2">
                                    <div class="text-muted mb-0">{{ $t('credit') }}</div>
                                    <div class="label-as-input text-center">
                                        {{
                                            data.item.course && data.item.course.credit ? parseInt(data.item.course.credit) : '-'
                                        }}
                                    </div>
                                </b-col>

                                <!-- Semester -->
                                <b-col cols="4" sm="4" lg="auto" class="mb-2">
                                    <div class="text-muted mb-0">{{ $t('semester') }}</div>
                                    <div class="label-as-input text-center">
                                        {{ data.item.semester }}
                                    </div>
                                </b-col>

                                <!-- Quota -->
                                <b-col cols="4" sm="4" lg="auto" class="mb-2">
                                    <div class="text-muted mb-0">{{ $t('quota') }}</div>
                                    <div class="label-as-input text-center">
                                        {{ data.item.quota ? data.item.quota : '-' }}
                                    </div>
                                </b-col>

                                <!-- Remaining quota -->
                                <b-col cols="4" sm="4" lg="auto" class="mb-2">
                                    <div class="text-muted mb-0">{{ $t('remaining_quota') }}</div>
                                    <div class="label-as-input text-center">
                                        {{ data.item.remaining_quota ? data.item.remaining_quota : '-' }}
                                    </div>
                                </b-col>

                                <!-- Sections -->
                                <b-col v-if="data.item.sections">
                                    <div class="text-muted mb-0">{{ $t('sections') }}</div>
                                    <b-row>
                                        <b-col cols="12" v-for="section in data.item.sections"
                                               :key="'section'+section.id">
                                            <div class="label-as-input p-2 mb-2">
                                                <strong>
                                                    Section {{ section.section }}<span
                                                    v-if="section.type==2"> - {{ $t('lab') }}</span> -
                                                    {{ section.instructor_name }}
                                                    <span v-if="section.definition"> - {{
                                                            section.definition
                                                        }}</span>
                                                </strong>
                                                <ul v-if="section.course_schedules.length" class="m-0">
                                                    <li v-for="schedule in section.course_schedules"
                                                        :key="'sectionSchedule'+schedule.id">
                                                        {{ getDayText(schedule.day) }}
                                                        {{ schedule.start_time }} - {{ schedule.end_time }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="col-12">
                            <template v-if="data.item.course_id">
                                <b-button variant="primary"
                                          @click="addCourse(data.item.id, data.item.course_id, data.item.course.name,getObjectValue(data.item.course,'code'))"
                                >
                                    <i class="ri-add-line mr-3"></i> {{ $t('take_the_lesson') }}
                                </b-button>
                            </template>
                        </div>
                    </div>
                </template>
            </b-table>
        </div>
    </template>
        <CommonModal ref="electiveModal" size="lg" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ electiveCoursePoolName ? electiveCoursePoolName : '' }}
            </template>
            <template v-slot:CommonModalContent>
                <div v-if="showElectiveModal">
                    <b-table :empty-filtered-text="$t('no_result')"
                             :empty-text="$t('no_result')"
                             bordered
                             responsive
                             :items="electiveCourses"
                             :fields="electiveCoursesFields"
                             show-empty
                             class="mb-4 table-dropdown no-scrollbar border rounded"
                             @row-clicked="onRowClicked"
                    >
                        <template #cell(add)="data">
                            <div class="w-40 h-40 cursor-pointer d-flex justify-content-center align-items-center">
                                <i :class="data.detailsShowing ? 'ri-arrow-up-s-line font-size-20':'ri-arrow-down-s-line font-size-20'"></i>
                            </div>
                        </template>
                        <template #cell(course_name)="data">
                            {{ data.item && data.item.course_name || '' }}
                        </template>
                        <template #row-details="data">
                            <b-row v-if="Object.keys(data.item.sections).length>0">
                                <b-col cols="12">
                                    <div class="text-muted mb-0">{{ $t('sections') }}</div>
                                    <b-row class="mb-1">
                                        <b-col cols="12">
                                            <b-table-simple responsive small class="mb-3 border rounded"
                                                            v-if="Object.values(data.item.sections).filter(item=>item.type==='1').length">
                                                <thead>
                                                    <tr style="border-bottom: 1px solid #e9ecef; background: #f1f1f1;">
                                                        <th colspan="6" class="text-center">
                                                            {{ $t('theoretical') }}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th class="text-center">{{ $t('section').toUpperCase() }}</th>
                                                        <th class="text-center">{{ $t('instructor') }}</th>
                                                        <th class="text-center">{{ $t('quota') }}</th>
                                                        <th class="text-center">{{
                                                                $t('remaining_quota')
                                                            }}
                                                        </th>
                                                        <th class="text-center">{{ $t('day') }},
                                                            {{ $t('hour') }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <b-tbody>
                                                    <b-tr v-for="section in data.item.sections" :key="section.id"
                                                          v-if="section.type==1">
                                                        <b-td class="p-1 text-center">
                                                            <b-form-radio v-model="electiveCourseTheoretical"
                                                                          :name="'course'+data.item.course_id+'-theoretical'"
                                                                          :value="section.id"/>
                                                        </b-td>
                                                        <b-td class="p-1 text-center">Section {{ section.section }}
                                                            <span v-if="section.definition"> - {{
                                                                    section.definition
                                                                }}</span></b-td>
                                                        <b-td class="p-1 text-center">{{
                                                                section.instructor_name
                                                            }}
                                                        </b-td>
                                                        <b-td class="p-1 text-center">{{ section.quota }}</b-td>
                                                        <b-td class="p-1 text-center">{{
                                                                section.remaining_quota
                                                            }}
                                                        </b-td>
                                                        <b-td class="p-1">
                                                            <template v-if="section.course_schedules.length">
                                                                <div v-for="schedule in section.course_schedules"
                                                                     :key="'sectionSchedule'+schedule.id" class="fs-14">
                                                                    <i class="ri-arrow-right-s-line mt-1"></i>
                                                                    {{ getDayText(schedule.day) }}
                                                                    {{ schedule.start_time }} - {{ schedule.end_time }}
                                                                </div>
                                                            </template>
                                                        </b-td>
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-table-simple hover responsive small class="mb-3 border rounded"
                                                            v-if="Object.values(data.item.sections).filter(item=>item.type==='2').length">
                                                <thead>
                                                    <tr style="border-bottom: 1px solid #e9ecef; background: #f1f1f1;">
                                                        <th colspan="6" class="text-center">{{
                                                                $t('practical')
                                                            }}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th class="text-center">{{ $t('section') }}</th>
                                                        <th class="text-center">{{ $t('instructor') }}</th>
                                                        <th class="text-center">{{ $t('quota') }}</th>
                                                        <th class="text-center">{{
                                                                $t('remaining_quota')
                                                            }}
                                                        </th>
                                                        <th class="text-center">{{ $t('day') }},
                                                            {{ $t('hour') }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <b-tbody>
                                                    <b-tr v-for="section in data.item.sections" :key="section.id"
                                                          v-if="section.type==2">
                                                        <b-td class="p-1 text-center">
                                                            <b-form-radio v-model="electiveCoursePratical"
                                                                          :name="'course'+data.item.course_id+'-practical'"
                                                                          :value="section.id"/>
                                                        </b-td>
                                                        <b-td class="p-1 text-center">Section {{ section.section }}
                                                            <span v-if="section.definition"> - {{
                                                                    section.definition
                                                                }}</span></b-td>
                                                        <b-td class="p-1 text-center">{{
                                                                section.instructor_name
                                                            }}
                                                        </b-td>
                                                        <b-td class="p-1 text-center">{{ section.quota }}</b-td>
                                                        <b-td class="p-1 text-center">{{
                                                                section.remaining_quota
                                                            }}
                                                        </b-td>
                                                        <b-td class="p-1">
                                                            <template v-if="section.course_schedules.length">
                                                                <div v-for="schedule in section.course_schedules"
                                                                     :key="'sectionSchedule'+schedule.id" class="fs-14">
                                                                    <i class="ri-arrow-right-s-line mt-1"></i>
                                                                    {{ getDayText(schedule.day) }}
                                                                    {{ schedule.start_time }} - {{ schedule.end_time }}
                                                                </div>
                                                            </template>
                                                        </b-td>
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12">
                                    <div class="d-flex">
                                        <!--
										<b-button variant="light"
										          class="mr-3"
										          @click="previewElectiveCourse()">
												<span><i class="ri-search-line mr-3"></i> {{ $t('preview') }}</span>
										</b-button>
										-->
                                        <b-button variant="primary"
                                                  @click="storeElectiveCourse(electiveCourseSlotId,data.item.course_id, data.item.course_name)">
                                            <span><i class="ri-add-line mr-3"></i> {{ $t('take_the_lesson') }}</span>
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                            <div v-else class="p-4">{{ $t('section_not_found') }}</div>
                        </template>
                    </b-table>
                </div>
            </template>
        </CommonModal>
    </div>
</template>

<script>
// Components
import CommonModal from "@/components/elements/CommonModal";

// Services
import CourseRegistration from "@/services/CourseRegistration";
import qs from "qs";

export default {
    components: {
        CommonModal
    },
    props: {
        slotData: {
            type: Object,
            default: null
        },
        studentProgramId: {
            default: null
        }
    },
    watch: {
        electiveCourseSelectIndex: {
            handler: function (value, exValue) {
                if (value != exValue) {
                    this.electiveCourseTheoretical = null;
                    this.electiveCoursePratical = null;
                }
            }
        }
    },
    data() {
        return {
            electiveCoursesFields: [
                {key: 'add', label: ' ', class: 'w-40 p-1 align-middle'},
                {
                    key: 'course_code',
                    label: this.$t('code'),
                    sortable: true,
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center'
                },
                {
                    key: 'course_name',
                    label: this.$t('course'),
                    sortable: true,
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center'
                },
                {
                    key: 'credit',
                    label: this.$t('credit'),
                    sortable: true,
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center'
                },
                {key: 'quota', label: this.$t('quota'), sortable: true, tdClass: 'text-center align-middle'},
            ],

            showElectiveModal: false,
            electiveCourses: [],
            electiveCoursePoolName: null,
            electiveCourseSlotId: null,

            electiveCourseDetailsRow: null,
            electiveCourseSelectIndex: null,
            electiveCourseTheoretical: null,
            electiveCoursePratical: null,
        }
    },
    methods: {
        onRowClicked(item, index) {
            const {electiveCourseDetailsRow} = this
            if (electiveCourseDetailsRow && electiveCourseDetailsRow !== item) {
                electiveCourseDetailsRow._showDetails = false;
            }

            this.$set(item, "_showDetails", !item._showDetails);
            this.electiveCourseDetailsRow = item._showDetails ? item : false;
            this.electiveCourseSelectIndex = item._showDetails ? index : null;
            if (this.electiveCourseSelectIndex === null) {
                this.electiveCourseTheoretical = null
                this.electiveCoursePratical = null
            }
        },
        getFields(slotCharCode, slotType) {
            let $return = [{key: 'add', label: this.$t('action'), class: 'w-40 p-1 align-middle'}];
            if (slotCharCode == 'M') {
                $return.push({
                    key: 'course_code',
                    label: this.$t('course_code'),
                    sortable: true,
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center'
                })
                $return.push({
                    key: 'course_name',
                    label: this.$t('course_name'),
                    sortable: true,
                    tdClass: 'text-left align-middle',
                    thClass: 'text-left'
                })
            } else {
                $return.push({
                    key: 'pool_name',
                    label: this.$t('lesson_pool'),
                    sortable: true,
                    tdClass: 'text-left align-middle',
                    thClass: 'text-left'
                })
            }

            $return.push({
                key: 'semester',
                label: this.$t('semester'),
                sortable: true,
                tdClass: 'text-center align-middle d-none d-lg-table-cell',
                thClass: 'text-center  d-none d-lg-table-cell'
            });
            $return.push({
                key: 'credit',
                label: this.$t('credit'),
                sortable: true,
                tdClass: 'text-center align-middle d-none d-lg-table-cell',
                thClass: 'text-center d-none d-lg-table-cell'
            });

            if (slotType != 'not_taken') {
                $return.push({
                    key: 'last_course',
                    label: this.$t('last_course'),
                    sortable: true,
                    tdClass: 'text-center align-middle d-none d-lg-table-cell',
                    thClass: 'text-center d-none d-lg-table-cell'
                });
                $return.push({
                    key: 'last_course_letter_grade',
                    label: this.$t('letter_grade'),
                    sortable: true,
                    tdClass: 'text-center align-middle d-none d-lg-table-cell',
                    thClass: 'text-center d-none d-lg-table-cell'
                });
            }
            return $return;
        },
        addCourse(slot_id, course_id, course_name,course_code) {
            this.$swal({
                title: this.$t('attention'),
                text: this.$t('are_you_sure_you_want_to_take_the_x_course', {course_name: course_name,course_code:course_code}),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then(result => {
                    if (result.isConfirmed) {
                        let formData = {
                            student_program_id: this.studentProgramId,
                            student_slot_id: slot_id,
                            course_id: course_id,
                        }

                        CourseRegistration.store(formData)
                            .then(response => {
                                if (typeof response.data.message != 'undefined') {
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                } else {
                                    this.$toast.success(this.$t('successfully_added'));
                                }
                                this.$emit('addedACourse', true);

                                if (typeof response.data.data.info != 'undefined' && response.data.data.info) {
                                    this.$swal({
                                        text: this.getInfoMessage(response.data.data),
                                        icon: 'info',
                                        confirmButtonText: this.$t('ok')
                                    });
                                }
                            })
                            .catch(e => {
                                this.showOverlapMessage(e);
                                this.showErrors(e)
                            });
                    }
                })
        },

        showOverlapMessage(e) {
            try {
                if (typeof e.data.message != 'undefined' && ['LAB_SCHEDULE_OVERLAP', 'SCHEDULE_OVERLAP'].includes(e.data.message)) {
                    let msg = this.$t('schedule_overlap_message')
                        .replaceAll('#course', e.data.errors[0].course.code + ' ' + e.data.errors[0].course.name);
                    this.$swal({text: msg, icon: 'error'});
                }
            } catch (e) {

            }
        },

        getElectiveCourses(id, pool_name) {
            this.electiveCourseSlotId = id;
            this.electiveCoursePoolName = pool_name;

            const config = {
                params: {
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            CourseRegistration.getElectiveCourses(id, config)
                .then(response => {
                    this.electiveCourses = response.data.data
                    this.showElectiveModal = true;
                })
                .then(response => {
                    this.$refs.electiveModal.$refs.commonModal.show()
                })
                .catch(e => {
                    this.showErrors(e)
                });
        },
        previewElectiveCourse() {
            let slot = this.electiveCourses[this.electiveCourseSelectIndex];
            let isRequredTheoretical = Object.values(slot.sections).filter(item => item.type === '1').length > 0;
            let isRequredPratical = Object.values(slot.sections).filter(item => item.type === '2').length > 0;

            if (isRequredTheoretical && this.electiveCourseTheoretical == null) {
                this.$toast.error('Hata');
                return false;
            }
            if (isRequredPratical && this.electiveCoursePratical == null) {
                this.$toast.error('Hata');
                return false;
            }


        },
        storeElectiveCourse(slot_id, course_id, course_name) {
            let slot = this.electiveCourses[this.electiveCourseSelectIndex];
            let isRequredTheoretical = Object.values(slot.sections).filter(item => item.type === '1').length > 0;
            let isRequredPratical = Object.values(slot.sections).filter(item => item.type === '2').length > 0;

            if (isRequredTheoretical && this.electiveCourseTheoretical == null) {
                this.$toast.error(this.$t('no_theoretical_course_selection_has_been_made'));
                return false;
            }
            if (isRequredPratical && this.electiveCoursePratical == null) {
                this.$toast.error(this.$t('no_practical_course_selection_has_been_made'));
                return false;
            }

            let formData = {
                student_program_id: this.studentProgramId,
                student_slot_id: slot_id,
                course_id: course_id,
            }
            if (isRequredTheoretical) formData.section_id = this.electiveCourseTheoretical;
            if (isRequredPratical) formData.lab_section_id = this.electiveCoursePratical;
            CourseRegistration.store(formData)
                .then(response => {
                    if (typeof response.data.message != 'undefined') {
                        this.$toast.success(this.$t('api.' + response.data.message));
                    } else {
                        this.$toast.success(this.$t('successfully_added'));
                    }
                    this.$emit('addedACourse', true);

                    if (typeof response.data.data.info != 'undefined' && response.data.data.info) {
                        this.$swal({
                            text: this.getInfoMessage(response.data.data),
                            icon: 'info',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                })
                .catch(e => {
                    this.showOverlapMessage(e);
                    this.showErrors(e)
                });
        },


    }
}
</script>
<style scoped>
.b-table-details td {
    background: #ffffff;
    padding: 20px;
}

.custom-control {
    padding-left: 3rem;
}
</style>
